<template>
  <div class="pr-5 pl-5">
    <div
      class="max-w-7xl bg-blue rounded-3xl flex-row-reverse lg:flex lg:flex-row text-white h-[440px] mt-32 mb-32 m-auto max-lg:overflow-hidden md:overflow-visable relative"
    >
      <div class="w-full lg:w-1/2">
        <nuxt-img
          width="45px"
          class="top-[51px] left-[560px] absolute"
          alt="Gear Image"
          src="/images/icons/home/gear.svg"
          loading="lazy"
        />
        <nuxt-img
          width="50px"
          class="bottom-[63px] left-[317px] lg:left-[626px] absolute"
          alt="Gear Image"
          src="/images/icons/home/gear.svg"
          loading="lazy"
        />
        <nuxt-img
          width="75px"
          class="bottom-[41px] lg:bottom-[285px] left-[130px] absolute"
          alt="Gear Image"
          src="/images/icons/home/gear.svg"
          loading="lazy"
        />
        <nuxt-img
          sizes="sm:140px lg:800px"
          class="absolute bottom-[-280px] right-[46px] lg:left-[123px] lg:bottom-[-1px] z-30"
          alt="Worker Image"
          src="/images/icons/worker.svg"
          loading="lazy"
        />
      </div>
      <div class="w-full lg:w-1/2 flex justify-end pr-32">
        <div
          class="w-full pl-10 lg:pl-0 lg:w-3/4 mt-16 lg:mt-28 relative z-40"
        >
          <h3 class="text-4xl font-semibold mb-5">
            Needy any <br />assistance?
          </h3>
          <p>
            If you need to hire something, we’re here to help you. We
            have a wide range of items available nationally.
          </p>
          <p class="mt-10">Email us: contact@justhire.com</p>
        </div>
        <nuxt-img
          class="hidden lg:block absolute left-0 top-0 h-[440px] z-0"
          alt="Gradient 2"
          src="/images/pages/home/lines-gradient-2.png"
          loading="lazy"
        />
        <nuxt-img
          class="lg:hidden absolute right-0 top-0 h-[440px] z-0"
          alt="Gradient"
          src="/images/pages/home/lines-gradient.png"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>
